export const themeColors = {
    // Primaries
    primary: "var(--color-primary)",
    primaryLight: "var(--color-primary-light)",
    primaryDark: "var(--color-primary-dark)",
    primary2: "var(--color-primary-2)",
    primary3: "var(--color-primary-3)",
    primary4: "var(--color-primary-4)",

    // Neutral Mains
    neutralWhite: "var(--color-neutral-white)",
    neutralBlack: "var(--color-neutral-black)",

    background: "var(--color-background)",

    pureWhite: "var(--color-pure-white)",
    pureBlack: "var(--color-pure-black)",

    // Neutral Grays
    neutral1: "var(--color-neutral-1)",
    neutral2: "var(--color-neutral-2)",
    neutral3: "var(--color-neutral-3)",

    // Neutral Overlays
    neutralOverlay: "var(--color-neutral-overlay)",

    // Notes
    docsLight: "var(--color-docs-light)",
    docsMed: "var(--color-docs-med)",
    docsDark: "var(--color-docs-dark)",

    // Flashcards
    flashcardsLight: "var(--color-flashcards-light)",
    flashcardsMed: "var(--color-flashcards-med)",

    // Videos
    videoLight: "var(--color-video-light)",
    videoMed: "var(--color-video-med)",
    videoDark: "var(--color-video-dark)",

    //audio
    audio: "var(--color-audio)",

    // PDFs
    pdfLight: "var(--color-pdf-light)",
    pdfMed: "var(--color-pdf-med)",
    pdfDark: "var(--color-pdf-dark)",

    // Excel
    excelLight: "var(--color-excel-light)",
    excelMed: "var(--color-excel-med)",

    // PowerPoint
    pptLight: "var(--color-ppt-light)",
    pptMed: "var(--color-ppt-med)",

    // Icons
    icon1: "var(--color-icon-1)",
    icon2: "var(--color-icon-2)",
    icon3: "var(--color-icon-3)",
    icon4: "var(--color-icon-4)",
    icon5: "var(--color-icon-5)",

    // Font Colors
    type1: "var(--color-type-1)",
    type2: "var(--color-type-2)",
    type3: "var(--color-type-3)",
    type4: "var(--color-type-4)",

    // Subjects
    subject1: "var(--color-subject-1)",
    subject2: "var(--color-subject-2)",
    subject3: "var(--color-subject-3)",
    subject4: "var(--color-subject-4)",
    subject5: "var(--color-subject-5)",

    // rich editor text colors
    richEditorTextColor1: "var(--color-rich-editor-text-1)",
    richEditorTextColor2: "var(--color-rich-editor-text-2)",
    richEditorTextColor3: "var(--color-rich-editor-text-3)",
    richEditorTextColor4: "var(--color-rich-editor-text-4)",
    richEditorTextColor5: "var(--color-rich-editor-text-5)",

    // Study
    mcStudyLight1: "var(--color-mc-study-1-light)",
    mcStudyMed1: "var(--color-mc-study-1-med)",
    mcStudyDark1: "var(--color-mc-study-1-dark)",

    mcStudyLight2: "var(--color-mc-study-2-light)",
    mcStudyMed2: "var(--color-mc-study-2-med)",
    mcStudyDark2: "var(--color-mc-study-2-dark)",

    mcStudyLight3: "var(--color-mc-study-3-light)",
    mcStudyMed3: "var(--color-mc-study-3-med)",
    mcStudyDark3: "var(--color-mc-study-3-dark)",

    mcStudyLight4: "var(--color-mc-study-4-light)",
    mcStudyMed4: "var(--color-mc-study-4-med)",
    mcStudyDark4: "var(--color-mc-study-4-dark)",

    // Success
    successPrimary: "var(--color-success-primary)",
    successSecondary: "var(--color-success-secondary)",

    // Error
    errorPrimary: "var(--color-error-primary)",
    errorSecondary: "var(--color-error-secondary)",

    // Gradients
    popupGradient: "var(--color-popup-gradient)",

    // OLD COLORS, DO NOT USE!!!
    /** @deprecated */
    secondary: "var(--color-secondary)",
    /** @deprecated */
    card: "var(--color-card-primary)",
    /** @deprecated */
    cardTertiary: "var(--color-card-tertiary)",
    /** @deprecated */
    searchBar: "var(--color-search-bar)",
    /** @deprecated */
    shadow: "var(--color-shadow)",
    /** @deprecated */
    line: "var(--color-line)",
    /** @deprecated */
    reversedButton: "var(--color-reversed-button)",
    /** @deprecated */
    inverted: "var(--color-inverted)",
    /** @deprecated */
    highlight: "var(--color-highlight)",
};

export const SUBJECT_CATEGORY_COLORS = {
    Math: themeColors.subject3,
    Science: themeColors.subject5,
    Language: themeColors.subject2,
    Engineering: themeColors.subject4,
    Business: themeColors.neutral1,
    "Social Studies": themeColors.subject1,
};
